@import '~include-media/dist/_include-media.scss';

$primary-blue: #0f0f22;
$secondary-blue: #f7f9fb;
$matte-blue: #d7e3ec;
$highlight-blue: #1589ee;
$background-black: #0f0f22;
$highlight-green: #53ffc8;
$shade-grey: #777777;
$dark-shade-grey: #414141;
$linked-in-blue: #0274B3;
$dark-grey: #f2f2f2;
$light-grey: #a3a3a3;
$light-green: #88fbcb;
$primary-dark: #06A764;
$background-grey: #c4c4c4;
$dark-blue: #191e39;
$button-blue: #b2d5ef;
$text-grey: #333333;
$background-blue: #181f39;
$search-green: #dcfff0;
$tag-green: #d7ffee;
$tag-grey: #e8e8e8;

$navbar-height: 88px;

@font-face {
  font-family: 'Gilroy';
  src: url('/gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/gilroy/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/gilroy/Gilroy-Medium.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

$breakpoints: (
  phone: 576px,
  tablet: 768px,
  desktop: 992px,
  desktop-large: 1200px
);
