@import 'src/variables.scss';

.nav-link-container-partner {
    display: flex;
    align-items: center;

    .menu-box-partner {
        visibility: hidden;
        margin: 3px 25px;
        pointer-events: none;
        div {
            margin: 2px;
            &:hover {
                background: $highlight-green;
            }
        }
    }

    &:hover {
        .menu-box-partner {
            visibility: unset;
            pointer-events: initial;
            cursor: pointer;
        }

        .nav-items-partner {
            background-color: white;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            text-align: left;
        }
    }

    .nav-items-partner {
        position: relative;
        z-index: 999;
        padding: 10px;

        &:hover{
            background-color: white;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        }        
    }
}

.nav-link-separator-partner {
    height: 50px;
    width: 1px;
    background-color: #A3A3A3;
    margin: 0 10px;
}

.nav-link-partner {
    font-size: 18px;
    font-weight: bold;
    color: #0F0F22;
    margin-top: 80px;
    text-align: right;

    a, a:hover {
        color: #0F0F22;
        text-decoration: none;
        font-weight: bold;
        font-size: 18px;
    }

    span {
        cursor: pointer;
    }
}
