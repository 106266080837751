@import 'src/variables.scss';

.premium-partner {
    font-family: 'Gilroy';

    .content{
        @media (max-width: 1440px) {
            margin: 38px 4% 5px 4%;;
        }
        margin: 38px 10% 5px 10%;

        .header {
            display: flex;

            .digitalboost-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 10px;
            }

            .partner-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 10px;
            }

            .partner-logo-exists{
                border-left: 1px solid #A3A3A3;
            }

            .header-navigation {
                margin-left: auto;
                display: flex;
                gap: 10px;
                height: 40px;

                button {
                    font-family: 'Gilroy';
                    font-size: 16px;
                    background: none!important;
                    border: none;
                    padding: 0!important;
                    cursor: pointer;
                    font-weight: bold;
                    outline : none;
                }
            }
        }
    }

    .partner-level {
        height: 21px;
        box-shadow: 0px -3px 1px rgba(0, 0, 0, 0.1);
        display: flex;

        .partner-level-content{
            @media (max-width: 1440px) {
                margin: 0 4% 0px auto;
            }
            margin: 0 10% 0px auto;
            align-self: flex-end;
            letter-spacing: 0.03em;
        }

        &.platinum{
            background: linear-gradient(90deg, #C4C4C4 0%, #EDEDED 100%);         
            opacity: 0.5;
        }

        &.gold{
            background: linear-gradient(90deg, #F4B53A 0%, #FAE4AC 100%);
            opacity: 0.5;
        }

        &.silver{
            background: linear-gradient(90deg, #8C8C8C 0%, #E7E7E7 70.31%, #B1B1B1 100%);
            opacity: 0.5;
        }

        &.bronze{
            background: linear-gradient(90deg, #E48743 0%, #E58845 0.01%, #F5E6C8 100%);
            opacity: 0.5;
        }

        &.headline, &.founding{
            background: linear-gradient(90deg, #268b30 0%, #D9FCED 100%);
            opacity: 0.5;
        }
    }

    .content-body{
        @media (max-width: 1440px) {
            margin: 1px 4% 5px 4%;;
        }
        margin: 1px 10% 5px 10%;

        .clickable{
            cursor: pointer;
        }

        .clickable:hover{
            background-color: #fafcfa;
            border: 1px solid blue;
        }

        .sub-header{
            font-size: 18px;
            line-height: 120%;
            letter-spacing: 0.01em;
            color: #181F39;
        }

        .main-metrics {
            display: flex;
            flex-wrap: nowrap;
            margin-top: 20px;
            height: 120px;
            
            div {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                text-align: center;
                border: 1px solid #A3A3A3;
                font-size: 14px;
                letter-spacing: 0.02em;

                .metric-box{
                    border: none;

                    .metric-value{
                        border: none;
                        font-size: 48px;
                        margin-top: 10px;
                    }

                    .metric-value-hidden{
                        border: none;
                        font-size: 16px;
                        margin-top: 10px;
                        margin-bottom: 39px;
                    }
                }
                
            }
        }

        .top-10-skills-requested,
        .mentoring-days-requests-occurrence {
            margin-top: 20px;
            border: 1px solid #A3A3A3;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

            .hide-metric {
                display: none;
            }

            .metric-header{
                display: flex;
                flex-wrap: nowrap;
                font-size: 18px;
                margin: 15px 15px 5px 15px;

                .metric-label{
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.01em;
                    font-weight: bold;
                }

                .controls{
                    margin-left: auto;
                }
            }

            .chart-options{
                display: flex;
                flex-wrap: nowrap;

                button{
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;
                    height: 32px;
                    background: #FFFFFF;
                    border: 1px solid #A3A3A3;
                    box-sizing: border-box;

                    &.selected{
                        background: #D9FCED;
                    }
                }

            }
        }

        .tableau-region {
            flex-wrap: nowrap;
            margin-top: 20px;
            border: 1px solid #A3A3A3;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

            .metric-header {
                display: flex;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                margin: 15px 15px 0 15px;

                .metric-label{
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.01em;
                    font-weight: bold;
                }

                .metric-value{
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.01em;
                    font-weight: bold;
                    margin-left: auto;
                }
            }
            .metric-content{
                margin: 15px;
            }
        }

        .divider {
            flex-wrap: nowrap;
            margin-top: 20px;
            border: 1px solid #E8E8E8;
        }

        .tableau-metric-region,
        .hours-contributed-box {
            flex-wrap: nowrap;
            margin-top: 20px;
            height: 63px;
            border: 1px solid #A3A3A3;
            display: flex;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

            .description-box{
                display: flex;
                align-items: center;
                margin-left: 15px;
            }

            .metric-label{
                font-size: 18px;
                line-height: 120%;
                letter-spacing: 0.01em;
                font-weight: bold;
            }

            .value {
                font-family: Gilroy-Bold;
                font-size: 28px;
                line-height: 31px;
                letter-spacing: 0.01em;
                text-align: right;
                color: #06A764;
                font-weight: bold;
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 10px;
            }
        }

    }

}

.window-portal {
    margin: 10px;

    .tableau-modal-header {
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.01em;
        font-weight: bold;
        margin-top: 5px;
    }

    .tableau-body {
        margin-top: 10px;
        border: 1px solid #A3A3A3;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
}
