@font-face {
  font-family: 'Gilroy';
  src: url("/src/fonts/gilroy/Gilroy-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url("/src/fonts/gilroy/Gilroy-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url("/src/fonts/gilroy/Gilroy-ExtraBold.otf") format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url("/src/fonts/gilroy/Gilroy-Medium.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Gilroy', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  padding: 0 14px;
  margin: 0 auto;
}

.react-datepicker__day {
  &:focus {
    outline: none;
  }
}
