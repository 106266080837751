@import 'src/variables.scss';

 .error {
        color: red;
        font-weight: 700;;
    }

.login-page{
    background: linear-gradient(90deg, #E7FFF5 0%, rgba(231, 255, 245, 0) 100%);
    text-align: center;

    &-partner{
        background: white;
    }

   

    .container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .inputs {
            width: 60%;
            min-width: 450px;
            div {

                margin-bottom: 25px;
            }
        }

         .forgot-password{
            color: $text-grey;
            position: absolute;
            bottom: 46px;
            right: 20%;
        }
    }

    .signup-text {
        padding: 50px;
    }

}