@import 'src/variables.scss';

.text-area-with-label {

    font-family: 'Gilroy';
    display: flex;
    flex-direction: column;

    label {
        padding: 12px 0 0 0;
        font-size: 14px;
        color: $shade-grey;
    }

    textarea {
        border: 1px solid $shade-grey;
        font-size: 16px !important;
        padding-left: 8px;
        font-family: 'Gilroy';
        padding: 6px 8px;
    }

}

.text-area-with-label__mandatory-asterisk {
    color: $primary-dark;
}
