@import 'src/variables.scss';

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;


    
    .quick-link {
      margin-bottom: 67px;
      margin-right: 55px;
      font-size: 17px;
      font-weight: 700;
      cursor: pointer;
      line-height: 10px;
      text-decoration-skip-ink: none;
    }

    .quick-link:hover {
      text-decoration: underline double $highlight-green;;
      text-decoration-thickness: 2px;
    }

    .profile-pic {
      padding-right: 25px;
      padding-top: 5px;
    
      img {
        border-radius: 50px;
        margin: -6px;
        border: 1px solid $highlight-green;
        top: 32px;
        }
    }

    .logo {
      display: block;
    }

    .nav-link-container {
      display: flex;
      align-items: center;
      margin-top: 64px;


     
      // &:hover {

      //  .menu-box {
      //   visibility: unset;
      //   pointer-events: initial;
      //   cursor: pointer;
      //   background: white;

      //   }
      // }
    }

    .nav-link-separator {
      height: 50px;
      width: 1px;
      background-color: #A3A3A3;
      margin: -21px 44px 41px -11px;


    }

    .nav-link {
      font-size: 18px;
      font-weight: bold;
      color: #0F0F22;

      .name {
        right: 106px;
        top: 30px;
      }

      svg {
        
       margin: 3px 3px -4px 3px;

      }

      a {
        color: #0F0F22;
        text-decoration: none;
      }

      span {
        cursor: pointer;
      }
    }
  }
}

.nav__burger-menu-container {
  
  .menu-box {
    visibility: hidden;
    margin: 3px 25px;
    pointer-events: none;
    div {
      margin: 2px;
      &:hover {
        background: $highlight-green;
      }
    }

  }


  &:hover {

    .menu-box {
     visibility: unset;
     pointer-events: initial;
     cursor: pointer;
     background: white;

     }
   }
}