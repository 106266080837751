
.partner-logo-wrapper{
    padding-top: 15px;
    .fileUploader {
        width: fit-content;

        .fileContainer{
            border: none;
            box-shadow: none;
            padding: 0;
        }
    }
}

.text-area-with-label{
    margin-bottom: 20px;
}
