@import 'src/variables.scss';

.button {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
}

.button-primary {
    font-family: gilroy;
    border: 1px solid $primary-blue;
    background: $primary-blue;
    color: white;
    padding: 6px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;
}

.button-tertiary {
    font-family: gilroy;
    border: none;
    background: $light-green;
    color: black;
    padding: 6px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;

    &.disabled {
        color: #777777;
        background-color: #E8E8E8;
    }
}

.button-tertiary:hover {
    box-shadow: 0 0 0 2px $light-green;
    text-decoration: none;
}

.button-red {
    font-family: gilroy;
    border: 1px solid $primary-blue;
    background: #FF5F5F;
    color: white;
    padding: 6px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;
    border-radius: 2px
}

.button-dark {
    font-family: gilroy;
    border: 1px solid $primary-blue;
    background: $text-grey;
    color: white;
    padding: 6px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;
}


.button-secondary {
    font-family: gilroy;
    border: 1px solid $primary-blue;
    box-sizing: border-box;
    padding: 6px;
    background: unset;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;

}   

.button-secondary:hover {
    background: $matte-blue;
    cursor: pointer;
}



.button-red:hover {
    background: red;
    cursor: pointer;
}

.button-link-inverse {
    background: transparent;
    color: white;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    text-decoration: underline;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-family: gilroy;
    font-weight: 400;
    transition: 0.3s;
}

.button-link-inverse:hover {
    box-shadow: 0 0 0 2px #fff;
    text-decoration: none;

}

.button-link {
    background: transparent;
    color: #000000;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    text-decoration: underline;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-family: gilroy;
    font-weight: 400;
    transition: 0.3s;
}

.button-link:hover {
    box-shadow: 0 0 0 2px #000000;
    text-decoration: none;
}

.button-white {
    background: white;
    color: $primary-blue;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-family: gilroy;
    font-weight: 700;
    transition: 0.3s;
}

.button-white:hover {
    box-shadow: 0 0 0 2px #fff;
    text-decoration: none;

}